import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import Banner from 'common/source/containers/Banner';
import Footer from 'common/source/containers/Footer';

import SEO from '../components/SEO';
import config from '../../data/SiteConfig';

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <SEO pageTitle={`404: No encontrado :( | ${config.siteTitle}`} />
      <ResetCSS />
      <GlobalStyle />

      <ContentWrapper>
        <Navbar />
        <Banner heading="Página no encontrada">
          Has llegado a una página que no existe... tristeza.
        </Banner>
        <Footer />
      </ContentWrapper>
    </Fragment>
  </ThemeProvider>
);

export default NotFoundPage;
